<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 font-weight-bold">
          Pilih Role
        </v-card-title>

        <v-card-text>
          <v-radio-group v-model="radioGroup">
            <v-radio
              color="primary"
              v-for="options in roleOptions"
              :key="options.name"
              :label="options.name"
              :value="options.value"
            ></v-radio>
          </v-radio-group>
        </v-card-text>

        <v-divider class="mx-4"></v-divider>

        <v-card-actions class="py-4">
          <v-spacer></v-spacer
          ><v-btn
            text
            color="grey darken-2"
            class="text-none"
            @click="closeDialog"
          >
            Batalkan
          </v-btn>
          <v-btn color="primary" class="text-none" @click="chooseRole">
            Konfirmasi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    closeDialog: Function,
  },

  data() {
    return {
      radioGroup: "lender",
      roleOptions: [
        {
          name: "Lender",
          value: "lender",
        },
        {
          name: "Borrower",
          value: "borrower",
        },
      ],
    };
  },

  methods: {
    chooseRole() {
      this.closeDialog();
      this.$router.push("/input-user/is" + this.radioGroup);
    },
  },
};
</script>
