<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <!-- <v-text-field
      v-model="formData.pengguna"
      :rules="[(v) => !!v || 'Mohon isi pengguna']"
      label="Pengguna"
      color="primary"
      outlined
      dense
      required
    ></v-text-field> -->

    <v-text-field
      v-model=" formLender.penyelenggara"
      :rules="[(v) => !!v || 'Mohon isi penyelenggara']"
      label="Penyelenggara"
      color="primary"
      outlined
      dense
      required
    ></v-text-field>

    <v-text-field
      v-model=" formLender.lender"
      :rules="[(v) => !!v || 'Mohon isi lender']"
      label="Lender"
      color="primary"
      outlined
      dense
      required
    ></v-text-field>

    <v-text-field
      v-model=" formLender.sumberDana"
      :rules="[(v) => !!v || 'Mohon isi sumber dana']"
      label="Sumber Dana"
      color="primary"
      outlined
      dense
      required
    ></v-text-field>

    <!-- <v-select
      v-model="formData.negaraDomisili"
      :items="negaraDomisiliOptions"
      item-text="name"
      item-value="value"
      :rules="[(v) => !!v || 'Mohon pilih negara domisili']"
      label="Negara Domisili"
      color="primary"
      outlined
      dense
      required
    ></v-select> -->

    <!-- <v-select
      v-model="formData.kewarganegaraan"
      :items="kewarganegaraanOptions"
      item-text="name"
      item-value="value"
      :rules="[(v) => !!v || 'Mohon pilih kewarganegaraan']"
      label="Kewarganegaraan"
      color="primary"
      outlined
      dense
      required
    ></v-select> -->

    <!-- <v-select
      v-model="formData.report"
      :items="reportOptions"
      item-text="name"
      item-value="value"
      :rules="[(v) => !!v || 'Mohon pilih report']"
      label="Report"
      color="primary"
      outlined
      dense
      required
    ></v-select> -->
  </v-form>
</template>

<script>
export default {
  props: {
    formLender: Object,
  },
  data: () => ({
    valid: true,
    negaraDomisiliOptions: [
      {
        name: "jenis 1",
        value: 1,
      },
      {
        name: "jenis 2",
        value: 2,
      },
      {
        name: "jenis 3",
        value: 3,
      },
    ],
    kewarganegaraanOptions: [
      {
        name: "jenis 1",
        value: 1,
      },
      {
        name: "jenis 2",
        value: 2,
      },
      {
        name: "jenis 3",
        value: 3,
      },
    ],
    reportOptions: [
      {
        name: "jenis 1",
        value: 1,
      },
      {
        name: "jenis 2",
        value: 2,
      },
      {
        name: "jenis 3",
        value: 3,
      },
    ],
  }),

  mounted() {
    this.formData = this.formLender;
  }

};
</script>
