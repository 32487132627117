<template>
  <v-stepper v-model="steps" flat class="my-8">
    <v-stepper-header>
      <v-stepper-step
        class="stepperText"
        color="primary"
        step="1"
        :complete="steps > 1"
      >
        Input Pengguna
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        class="stepperText"
        color="primary"
        step="2"
        :complete="steps > 2"
      >
        Input {{ role || "L/B" }}
      </v-stepper-step>

     
    </v-stepper-header>
  </v-stepper>
</template>

<script>
export default {
  props: {
    steps: {
      type: Number,
      default: 1,
    },
    role: {
      type: String,
      default: "Lender",
    },
  },
};
</script>
