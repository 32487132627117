<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <!-- <v-text-field
      v-model="formData.pengguna"
      :rules="[(v) => !!v || 'Mohon isi pengguna']"
      label="Pengguna"
      color="primary"
      outlined
      dense
      required
    ></v-text-field> -->

    <v-text-field
      v-model="formData.penyelenggara"
      :rules="[(v) => !!v || 'Mohon isi penyelenggara']"
      label="Penyelenggara"
      color="primary"
      outlined
      dense
      required
    ></v-text-field>

    <v-text-field
      v-model="formData.borrower"
      :rules="[(v) => !!v || 'Mohon isi borrower']"
      label="Borrower"
      color="primary"
      outlined
      dense
      required
    ></v-text-field>

    <v-select
      v-model="formData.statusKepemilikanRumah"
      :items="statusKepemilikanRumahOptions"
      item-text="name"
      item-value="value"
      :rules="[(v) => !!v || 'Mohon pilih status kepemilikan rumah']"
      label="Status Kepemilikan Rumah"
      color="primary"
      outlined
      dense
      required
    ></v-select>

  </v-form>
</template>

<script>
export default {
  props: {
    formBorrower: Object,
  },
  data: () => ({
    valid: true,
    formData: {
      pengguna: "",
      penyelenggara: "",
      borrower: "",
      statusKepemilikanRumah: null,
      report: null,
    },
    statusKepemilikanRumahOptions: [
      {
        name: "Memiliki Rumah Sendiri",
        value: 1,
      },
      {
        name: "Tidak Memiliki Rumah Sendiri",
        value: 2,
      },
      {
        name: "Badan Hukum",
        value: 3,
      },
      {
        name: "Lainnya",
        value: 4,
      },
    ],
    reportOptions: [
      {
        label: "Ya",
        id: 1,
      },
      {
        label: "Tidak",
        id: 0,
      },
    ],
  }),

  mounted() {
    this.formData = this.formBorrower;

  },
 
};
</script>
