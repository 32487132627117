<template>
  <v-card class="pa-8">
    <header-content :pageTitle="'Input Data Pengguna'" />
    <stepper-user :steps="steps" :role="role" />

    <div v-if="!this.$route.params.role && !this.$route.params.type">
      <h4 class="pb-4">Excel Input</h4>
      <VueFileAgent
        :theme="'list'"
        :deletable="true"
        :accept="
          '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
        "
        :helpText="'Pilih file excel yang ingin diupload'"
        v-model="uploadedFile"
        @beforedelete="deleteFile($event)"
        ref="excelFile"
      ></VueFileAgent>
      <button-form
        :submit="submitExcelUser"
        :buttonText="'Upload'"
        :loading="loading"
        class="pt-8"
      />

      <v-divider class="my-6" />

      <h4 class="pb-4">Form Input</h4>
      <form-user :formUser="formNewUser" />
      <button-form :submit="submitUser" :loading="loading" class="pt-8" />
      <popup-role
        v-if="showDialog"
        :dialog="showDialog"
        :closeDialog="closePopupDialog"
      />
    </div>

    <div v-if="this.$route.params.role == 'lender'">
      <h4 class="pb-4">Excel Input</h4>
      <VueFileAgent
        :theme="'list'"
        :deletable="true"
        :accept="
          '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
        "
        :helpText="'Pilih file excel yang ingin diupload'"
        v-model="uploadedFile"
        @beforedelete="deleteFile($event)"
        ref="excelFile"
      ></VueFileAgent>
      <button-form
        :submit="submitExcelLender"
        :buttonText="'Upload'"
        :loading="loading"
        class="pt-8"
      />

      <v-divider class="my-6" />

      <h4 class="pb-4">Form Lender</h4>
      <form-lender :formLender="formNewLender" />
      <button-form :submit="submitLender" :loading="loading" class="pt-8" />
    </div>

    <div v-if="this.$route.params.role == 'borrower'">
      <h4 class="pb-4">Excel Input</h4>
      <VueFileAgent
        :theme="'list'"
        :deletable="true"
        :accept="
          '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
        "
        :helpText="'Pilih file excel yang ingin diupload'"
        v-model="uploadedFile"
        @beforedelete="deleteFile($event)"
        ref="excelFile"
      ></VueFileAgent>
      <button-form
        :submit="submitExcelBorrower"
        :buttonText="'Upload'"
        :loading="loading"
        class="pt-8"
      />

      <v-divider class="my-6" />

      <h4 class="pb-4">Form Borrower</h4>
      <form-borrower :formBorrower="formNewBorrower" />
      <button-form :submit="submitBorrower" :loading="loading" class="pt-8" />
    </div>

    <popup-success
      :dialog="showSuccessDialog"
      :closeDialog="closeSuccessDialog"
    />
    <popup-error :dialog="showErrDialog" :closeDialog="closeErrDialog" />
  </v-card>
</template>

<script>
import store from "../../store/index.js";
import HeaderContent from "../../components/Header/HeaderContent.vue";
import StepperUser from "../../components/Stepper/StepperUser.vue";
import PopupRole from "../../components/Popup/PopupRole.vue";
import ButtonForm from "../../components/Button/ButtonForm.vue";
import FormUser from "../../components/Form/FormUser.vue";
import FormLender from "../../components/Form/FormLender.vue";
import FormBorrower from "../../components/Form/FormBorrower.vue";
import PopupSuccess from "../../components/Popup/PopupSuccess.vue";
import PopupError from "../../components/Popup/PopupError.vue";

export default {
  components: {
    HeaderContent,
    StepperUser,
    PopupRole,
    PopupSuccess,
    PopupError,
    ButtonForm,
    FormUser,
    FormLender,
    FormBorrower,
  },

  data() {
    return {
      steps: 1,
      uploadedFile: "",
      showDialog: false,
      showSuccessDialog: false,
      showErrDialog: false,
      loading: false,
      role: "",
      formNewUser: {
        idPenyelenggara: "",
        idPenggunaRebel: "",
        idPengguna: "",
        namaPengguna: "",
        jenisPengguna: null,
        tanggalRegistrasi: "",
        jenisIdentitas: null,
        nomorIdentitas: "",
        nomorNpwp: "",
        jenisBadanHukum: null,
        tempatLahir: "",
        tanggalLahir: "",
        jenisKelamin: null,
        alamat: "",
        kota: null,
        provinsi: null,
        kodePos: "",
        agama: null,
        perkawinan: null,
        pekerjaan: null,
        bidangPekerjaan: null,
        pekerjaanOnline: null,
        pendapatan: null,
        pengalamanKerja: null,
        pendidikan: null,
      },
      formNewLender: {
        pengguna: 1,
        penyelenggara: null,
        lender: null,
        sumberDana: "",
        negaraDomisili: 0,
        kewarganegaraan: 0,
        report: null,
      },
      formNewBorrower: {
        pengguna: "",
        penyelenggara: "",
        borrower: "",
        statusKepemilikanRumah: null,
        report: null,
      },
    };
  },

  async mounted() {
    if (this.$route.params.role) {
      this.steps = 2;
      this.role = this.$route.params.role;
    } else if (this.$route.params.type) {
      this.steps = 3;
    } else {
      this.steps = 1;
    }
    await store.dispatch("options/fetchLoanApplications");
  },

  updated() {
    if (this.$route.params.role) {
      this.steps = 2;
    } else if (this.$route.params.type && this.steps != 4) {
      this.steps = 3;
    } else if (this.$route.params.type && this.steps == 4) {
      this.steps = 4;
    } else {
      this.steps = 1;
    }
  },

  methods: {
    closeSuccessDialog() {
      this.showSuccessDialog = !this.showSuccessDialog;
      this.$router.push("/list-user");
    },
    closeErrDialog() {
      this.showErrDialog = !this.showErrDialog;
    },
    closePopupDialog() {
      this.showDialog = !this.showDialog;
    },
    deleteFile(fileRecord) {
      this.$refs.excelFile.deleteFileRecord(fileRecord);
      this.uploadedFile = "";
    },
    async submitExcelUser() {
      if (this.uploadedFile) {
        this.loading = true;
        let payload = new FormData();
        payload.append("table", "pengguna");
        payload.append("json", "bulk");
        payload.append("file", this.uploadedFile.file);
        await store.dispatch("office/addExcel", payload).then((r) => {
          if (r) {
            console.log(r, "sukses");
            this.showDialog = !this.showDialog;
            this.loading = false;
          } else {
            this.showErrDialog = true;
            this.loading = false;
          }
        });
      } else {
        this.showErrDialog = true;
      }
    },
    async submitExcelBorrower() {
      this.loading = true;
      let payload = new FormData();
      payload.append("table", "borrower");
      payload.append("json", "bulk");
      payload.append("file", this.uploadedFile.file);
      if (this.uploadedFile) {
        await store.dispatch("office/addExcel", payload).then((r) => {
          if (r) {
            console.log(r, "sukses");
            this.showDialog = !this.showDialog;
            this.loading = false;
          } else {
            this.showErrDialog = true;
            this.loading = false;
          }
        });
      }
    },
    async submitExcelLender() {
      this.loading = true;
      let payload = new FormData();
      payload.append("table", "lender");
      payload.append("json", "bulk");
      payload.append("file", this.uploadedFile.file);
      if (this.uploadedFile) {
        await store.dispatch("office/addExcel", payload).then((r) => {
          if (r) {
            console.log(r, "sukses");
            this.showDialog = !this.showDialog;
            this.loading = false;
          } else {
            this.showErrDialog = true;
            this.loading = false;
          }
        });
      }
    },
    async submitUser() {
      this.uploadedFile = "";
      this.loading = false;
      try {
        console.log(this.formNewUser);
        await store
          .dispatch("office/addNewUser", this.formNewUser)
          .then((r) => {
            if (r) {
              console.log(r, "sukses");
              this.showDialog = !this.showDialog;
              this.loading = false;
            } else {
              this.showErrDialog = true;
              this.loading = false;
            }
          })
          .catch((err) => {
            console.log("error", err);
            this.loading = false;
          });
        this.steps = 2;
      } catch (err) {
        this.showErrDialog = true;
        this.loading = false;
      }
    },
    async submitLender() {
      this.uploadedFile = "";
      this.loading = false;
      // try {
      this.formNewLender.pengguna = localStorage.getItem("newIDPengguna");

      console.log(this.formNewLender);

      await store
        .dispatch("office/addNewLender", this.formNewLender)
        .then((r) => {
          if (r) {
            console.log(r, "sukses");
            this.showSuccessDialog = true;
            this.loading = false;
          } else {
            this.showErrDialog = true;
            this.loading = false;
          }
        });

      // } catch (err) {
      // this.showErrDialog = true;
      // }
    },
    async submitBorrower() {
      this.uploadedFile = "";
      this.loading = false;
      try {
        console.log(this.formNewBorrower);
        this.formNewBorrower.pengguna = localStorage.getItem("newIDPengguna");

        this.steps = 3;
        this.$router.push("/input-user/peminjam");
        await store
          .dispatch("office/addNewBorrower", this.formNewBorrower)
          .then((r) => {
            if (r) {
              console.log(r, "sukses");
              this.showSuccessDialog = true;
              this.loading = false;
            } else {
              this.showErrDialog = true;
              this.loading = false;
            }
          })
          .catch((err) => {
            console.log("error", err);
            this.loading = false;
          });
      } catch (err) {
        this.showErrDialog = true;
        this.loading = false;
      }
    },
  },
};
</script>
