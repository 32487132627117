<template>
  <v-form
    class="multi-col-validation"
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.idPenyelenggara"
          :rules="[(v) => !!v || 'Mohon isi id penyelenggara']"
          label="ID Penyelenggara"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.idPenggunaRebel"
          :rules="[(v) => !!v || 'Mohon isi id pengguna Old']"
          label="ID Pengguna Old"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="formData.idPengguna"
          :rules="[(v) => !!v || 'Mohon isi id pengguna']"
          label="ID Pengguna"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="formData.namaPengguna"
          :rules="[(v) => !!v || 'Mohon isi nama pengguna']"
          label="Nama Pengguna"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-select
          v-model="formData.jenisPengguna"
          :items="jenisPenggunaOptions"
          item-text="label"
          item-value="id"
          :rules="[(v) => !!v || 'Mohon pilih jenis pengguna']"
          label="Jenis Pengguna"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-select>
      </v-col>

      <v-col cols="12" md="6">
        <v-menu
          ref="tanggalRegistrasiMenu"
          v-model="tanggalRegistrasiMenu"
          :close-on-content-click="false"
          :return-value.sync="formData.tanggalRegistrasi"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formData.tanggalRegistrasi"
              label="Tanggal Registrasi"
              readonly
              v-bind="attrs"
              v-on="on"
              color="primary"
              outlined
              dense
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="formData.tanggalRegistrasi"
            color="primary"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="tanggalRegistrasiMenu = false">
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="
                $refs.tanggalRegistrasiMenu.save(formData.tanggalRegistrasi)
              "
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" md="6">
        <v-select
          v-model="formData.jenisIdentitas"
          :items="jenisIdentitasOptions"
          item-text="label"
          item-value="id"
          :rules="[(v) => !!v || 'Mohon isi jenis identitas']"
          label="Jenis Identitas"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-select>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.nomorIdentitas"
          :rules="[(v) => !!v || 'Mohon isi nomor Identitas']"
          label="Nomor Identitas"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.nomorNpwp"
          :rules="[(v) => !!v || 'Mohon isi nomor NPWP']"
          label="Nomor NPWP"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-select
          v-model="formData.jenisBadanHukum"
          :items="jenisBadanHukumOptions"
          item-text="label"
          item-value="id"
          :rules="[(v) => !!v || 'Mohon pilih jenis badan hukum']"
          label="Jenis Badan Hukum"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-select>
      </v-col>

      <v-col cols="12" md="6">
        <v-select
          v-model="formData.tempatLahir"
          :items="kotaOptions"
          item-text="label"
          item-value="id"
          :rules="[(v) => !!v || 'Mohon isi tempat lahir']"
          label="Tempat Lahir"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-select>
      </v-col>

      <v-col cols="12" md="6">
        <v-menu
          ref="tanggalLahirMenu"
          v-model="tanggalLahirMenu"
          :close-on-content-click="false"
          :return-value.sync="formData.tanggalLahir"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formData.tanggalLahir"
              label="Tanggal Lahir"
              readonly
              v-bind="attrs"
              v-on="on"
              color="primary"
              outlined
              dense
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="formData.tanggalLahir"
            color="primary"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="tanggalLahirMenu = false">
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.tanggalLahirMenu.save(formData.tanggalLahir)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" md="6">
        <v-select
          v-model="formData.jenisKelamin"
          :items="jenisKelaminOptions"
          item-text="label"
          item-value="id"
          :rules="[(v) => !!v || 'Mohon pilih jenis kelamin']"
          label="Jenis Kelamin"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-select>
      </v-col>

      <v-col cols="12" md="6">
        <v-select
          v-model="formData.pendidikan"
          :items="pendidikanOptions"
          item-text="label"
          item-value="id"
          :rules="[(v) => !!v || 'Mohon pilih pendidikan']"
          label="Pendidikan"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-select>
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="formData.alamat"
          :rules="[(v) => !!v || 'Mohon isi alamat']"
          label="Alamat"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-select
          v-model="formData.kota"
          :items="kotaOptions"
          item-text="label"
          item-value="id"
          :rules="[(v) => !!v || 'Mohon pilih kota']"
          label="Kota"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-select>
      </v-col>

      <v-col cols="12" md="6">
        <v-select
          v-model="formData.provinsi"
          :items="provinsiOptions"
          item-text="label"
          item-value="id"
          :rules="[(v) => !!v || 'Mohon pilih provinsi']"
          label="Provinsi"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-select>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formData.kodePos"
          :rules="[(v) => !!v || 'Mohon isi kode pos']"
          label="Kode Pos"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-select
          v-model="formData.agama"
          :items="agamaOptions"
          item-text="label"
          item-value="id"
          :rules="[(v) => !!v || 'Mohon pilih agama']"
          label="Agama"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-select>
      </v-col>

      <v-col cols="12" md="6">
        <v-select
          v-model="formData.perkawinan"
          :items="perkawinanOptions"
          item-text="label"
          item-value="id"
          :rules="[(v) => !!v || 'Mohon pilih status perkawinan']"
          label="Status Perkawinan"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-select>
      </v-col>

      <v-col cols="12" md="6">
        <v-select
          v-model="formData.pekerjaan"
          :items="pekerjaanOptions"
          item-text="label"
          item-value="id"
          :rules="[(v) => !!v || 'Mohon pilih pekerjaan']"
          label="Pekerjaan"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-select>
      </v-col>

      <v-col cols="12" md="6">
        <v-select
          v-model="formData.bidangPekerjaan"
          :items="bidangPekerjaanOptions"
          item-text="label"
          item-value="id"
          :rules="[(v) => !!v || 'Mohon pilih bidang pekerjaan']"
          label="Bidang Pekerjaan"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-select>
      </v-col>

      <v-col cols="12" md="6">
        <v-select
          v-model="formData.pekerjaanOnline"
          :items="pekerjaanOnlineOptions"
          item-text="label"
          item-value="id"
          :rules="[(v) => !!v || 'Mohon pilih pekerjaan online']"
          label="Pekerjaan Online"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-select>
      </v-col>

      <v-col cols="12" md="6">
        <v-select
          v-model="formData.pendapatan"
          :items="pendapatanOptions"
          item-text="label"
          item-value="id"
          :rules="[(v) => !!v || 'Mohon pilih pendapatan']"
          label="Pendapatan"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-select>
      </v-col>

      <v-col cols="12" md="6">
        <v-select
          v-model="formData.pengalamanKerja"
          :items="pengalamanKerjaOptions"
          item-text="label"
          item-value="id"
          :rules="[(v) => !!v || 'Mohon pilih pengalaman kerja']"
          label="Pengalaman Kerja"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-select>
      </v-col>

    
    </v-row>
  </v-form>
</template>

<script>
import store from "../../store/index.js";

export default {
  props: {
    formUser: Object,
  },
  data: () => ({
    valid: true,
    formData: {
      idPenyelenggara: "",
      idPenggunaRebel: "",
      idPengguna: "",
      namaPengguna: "",
      jenisPengguna: null,
      tanggalRegistrasi: "",
      jenisIdentitas: null,
      nomorIdentitas: "",
      nomorNpwp: "",
      jenisBadanHukum: null,
      tempatLahir: "",
      tanggalLahir: "",
      jenisKelamin: null,
      alamat: "",
      kota: null,
      provinsi: null,
      kodePos: "",
      agama: null,
      perkawinan: null,
      pekerjaan: null,
      bidangPekerjaan: null,
      pekerjaanOnline: null,
      pendapatan: null,
      pengalamanKerja: null,
      pendidikan: null,
      isReport: null,
    },
    jenisPenggunaOptions: [
      {
        label: "Orang",
        id: 1,
      },
      {
        label: "Badan Hukum",
        id: 2,
      },
    ],
    tanggalRegistrasiMenu: false,
    jenisIdentitasOptions: [
      {
        label: "KTP",
        id: 1,
      },
      {
        label: "Paspor",
        id: 2,
      },
      {
        label: "NPWP",
        id: 3,
      },
    ],
    jenisBadanHukumOptions: [],
    tanggalLahirMenu: false,
    jenisKelaminOptions: [],
    kotaOptions: [],
    provinsiOptions: [],
    agamaOptions: [],
    perkawinanOptions: [],
    pekerjaanOptions: [],
    bidangPekerjaanOptions: [],
    pekerjaanOnlineOptions: [
      {
        label: "Berbasis Internet/Online",
        id: 1,
      },
      {
        label: "Tidak Berbasis Internet/Online",
        id: 2,
      },
      {
        label: "Tidak Ada Data",
        id: 3,
      },
    ],
    pendapatanOptions: [],
    pengalamanKerjaOptions: [],
    pendidikanOptions: [],
    isReportOptions: [
      {
        label: "Ya",
        id: 1,
      },
      {
        label: "Tidak",
        id: 0,
      },
    ],
  }),
  // async beforeUpdate() {
  // await store.dispatch("office/fetchUser", this.$route.params.id);
  // this.formData = {
  //   idPenyelenggara: store.state.office.currentUser.id_penyelenggara,
  //   idPenggunaRebel: store.state.office.currentUser.id_pengguna_rebel,
  //   idPengguna: store.state.office.currentUser.id_pengguna,
  //   namaPengguna: store.state.office.currentUser.nama_pengguna,
  //   jenisPengguna: store.state.office.currentUser.jenis_pengguna,
  //   tanggalRegistrasi: store.state.office.currentUser.tgl_registrasi,
  //   jenisIdentitas: store.state.office.currentUser.jenis_identitas,
  //   nomorIdentitas: store.state.office.currentUser.no_identitas,
  //   nomorNpwp: store.state.office.currentUser.no_npwp,
  //   jenisBadanHukum: store.state.office.currentUser.id_jenis_badan_hukum,
  //   tempatLahir: store.state.office.currentUser.tempat_lahir,
  //   tanggalLahir: store.state.office.currentUser.tgl_lahir,
  //   jenisKelamin: store.state.office.currentUser.id_jenis_kelamin,
  //   alamat: store.state.office.currentUser.alamat,
  //   kota: store.state.office.currentUser.id_kota,
  //   provinsi: store.state.office.currentUser.id_provinsi,
  //   kodePos: store.state.office.currentUser.kode_pos,
  //   agama: store.state.office.currentUser.id_agama,
  //   perkawinan: store.state.office.currentUser.id_status_perkawinan,
  //   pekerjaan: store.state.office.currentUser.id_perkerjaan,
  //   bidangPekerjaan: store.state.office.currentUser.id_bidang_pekerjaan,
  //   pekerjaanOnline: store.state.office.currentUser.id_pekerjaan_online,
  //   pendapatan: store.state.office.currentUser.pendapatan,
  //   pengalamanKerja: store.state.office.currentUser.pengalaman_kerja,
  //   pendidikan: store.state.office.currentUser.id_pendidikan,
  // };

  // this.formData = this.formUser;
  // },
  async mounted() {
    // if(update){
    //
    // }

    this.formData = this.formUser;

    await store.dispatch("options/fetchCities");
    this.kotaOptions = store.state.options.cities;

    await store.dispatch("options/fetchProvinces");
    this.provinsiOptions = store.state.options.provinces;

    await store.dispatch("options/fetchReligions");
    this.agamaOptions = store.state.options.religions;

    await store.dispatch("options/fetchWorkFields");
    this.bidangPekerjaanOptions = store.state.options.workFields;

    await store.dispatch("options/fetchGenders");
    this.jenisKelaminOptions = store.state.options.genders;

    await store.dispatch("options/fetchJobs");
    this.pekerjaanOptions = store.state.options.jobs;

    await store.dispatch("options/fetchIncomes");
    this.pendapatanOptions = store.state.options.incomes;

    await store.dispatch("options/fetchWorkExperiences");
    this.pengalamanKerjaOptions = store.state.options.workExperiences;

    await store.dispatch("options/fetchLegalEntities");
    this.jenisBadanHukumOptions = store.state.options.legalEntities;

    await store.dispatch("options/fetchEducations");
    this.pendidikanOptions = store.state.options.educations;

    await store.dispatch("options/fetchMaritalStatuses");
    this.perkawinanOptions = store.state.options.maritalStatuses;
  },
};
</script>
